@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600&family=Pacifico&display=swap');

.font-dancing {
  font-family: 'Allura', cursive;
}
.font-pop{
font-family: 'Poppins', sans-serif; 
}
.Playfair{
font-family: 'Playfair Display', serif;
}
.custom-border {
  position: relative;
}

.custom-border::before {
  content: "";
  position: absolute;
  bottom: -4px; /* Adjust this value to move the border up or down */
  left: 0;
  right: 0;
  height: 8px; /* Border thickness */
  background-color: #f794e9;
}
